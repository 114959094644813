<template>
    <Carousel wrap-around :model-value="slideId" @update:modelValue="slideChanged">

        <template #slides>
            <Slide v-for="image in images" :key="image.uid">
                <MultiImage v-if="image.type === 'image'" :image="image" :height="390" />

                <iframe
                    v-else-if="image.type === 'youtube'"
                    :src="'https://www.youtube.com/embed/' + image.youtube_id"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </Slide>
        </template>

        <template #addons="{ currentSlide, slidesCount }">
            <Pagination />

            <div class="carousel__slides-counter">{{ currentSlide + 1 }}/{{ slidesCount }}</div>
        </template>
    </Carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination} from 'vue3-carousel';
import MultiImage from "./MultiImage";
import {ref} from "vue";
import {onBeforeRouteUpdate} from "vue-router";

export default {
    name: 'ImagesCarousel',
    components: {
        MultiImage,
        Carousel,
        Slide,
        Pagination,
    },

    props: {
        'images': Array,
    },

    setup() {
        const slideId = ref(0);

        onBeforeRouteUpdate(() => {
            slideId.value = 0;
        });

        function slideChanged(e) {
            slideId.value = e;
        }

        return { slideId, slideChanged };
    },
};
</script>

<style lang="scss"> // Must not be scoped to be able to override carousel styles

    .carousel__viewport {
        background-color: $gray-light;
    }

    .carousel__pagination {
        align-items: center;
        height: 3rem;
        margin-bottom: 0;
        padding-left: 0;
    }
    .carousel__pagination-item {

    }
    .carousel__pagination-button {
        display: block;
        width: auto;
        height: auto;
        margin: 0;
        padding: 5px;
        background-color: transparent;
        opacity: 0.3;
        transition: opacity 0.4s;

        &::before {
            content: "";
            display: block;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background-color: $blue-dark;
        }

        &.carousel__pagination-button--active {
            opacity: 1;
        }
    }

    .carousel__slides-counter {
        position: absolute;
        bottom: 1rem + 3rem;
        right: 1rem;
        padding: 0.45rem 0.5rem;
        line-height: 1;
        background-color: $white;
    }
</style>
