<template>
    <div class="multi-image-container" :style="'height:'+ height +'px'">
        <div class="image" :style="'background-image:url(\''+ image.preview +'\')'"></div>
        <div class="image" :style="'background-image:url(\''+ image.full +'\')'"></div>
    </div>
</template>

<script>
export default {
    name : 'MultiImage',

    props: {
        image: Object,
        height: Number
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.multi-image-container {
    position: relative;
    width: 100%;

& > .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
}

</style>
