<template>
    <div class="home">
        <div class="spinner"></div>

        <div class="progress-bar">
            <div class="progress" :style="{width: progress}"></div>
        </div>
    </div>
</template>

<script>

import {inject, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

export default {
    name: 'Login',
    components: {
    },
    setup() {
        const progress = ref('0%');
        const router = useRouter();
        const apiService = inject('apiService');

        function redirectToWorks() {
            router.push('/works');
        }

        function updateProgress(floatProgress) {
            progress.value = (floatProgress * 100) + '%';
        }

        function updateImagesProgress(doneCount, totalCount) {
            let imagesPercentage = doneCount / totalCount;

            updateProgress(0.1 + (imagesPercentage * 0.9));
        }

        function timeout(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        const startDownload = async () => {
            updateProgress(0.0);

            // Fetch API data
            let works = apiService.getWorks();
            let artists = apiService.getArtists();
            works = await works;
            artists = await artists;

            // Let's arbitrarily say that works and artists lists are 10% of the download volume...
            updateProgress(0.1);

            // Queue images to download
            let imagesToDownload = [];

            for (let work of works) {
                if (work.cover_image) {
                    imagesToDownload.push(work.cover_image);
                }

                for (let image of work.images) {
                    if (image.type === 'image') {
                        imagesToDownload.push(image.preview);
                    }
                }
            }

            for (let artist of artists) {
                for (let image of artist.images) {
                    if (image.type === 'image') {
                        imagesToDownload.push(image.preview);
                    }
                }
            }

            // Download all images
            let downloadedImagesCount = 0;
            let downloadingCount = 0;

            for (let i = 0; i < imagesToDownload.length; i++) {
                const img = new Image();
                img.onload = () => {
                    downloadedImagesCount++;
                    downloadingCount--;
                    updateImagesProgress(downloadedImagesCount, imagesToDownload.length);
                };

                // Start to download the image
                downloadingCount++;
                img.src = imagesToDownload[i];

                // If too many images are already trying to be downloaded, wait for some time
                while (downloadingCount >= 15) {
                    await timeout(25);
                }
            }

            // Redirect to the works page
            redirectToWorks();
        };

        onMounted(startDownload);

        return { progress };
    }
}
</script>

<style lang="scss" scoped>

@keyframes spin {
    from { transform: rotateZ(0); }
    to   { transform: rotateZ(360deg); }
}

.home {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    background-color: $blue-dark;
    color: $white;
}

.spinner {
    margin: 0 auto;
}

.progress-bar {
    height: 0.3rem;
    margin: 4rem 2rem 0 2rem;
    background-color: fade-out($white, 0.8);

    & > .progress {
        height: 100%;
        width: 0;
        background-color: $white;
        transition: width 0.1s;
    }
}

</style>
