<template>
    <slot />
</template>

<script>
export default {
    components: {

    }
};
</script>
