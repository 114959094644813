<template>
    <Suspense>
        <template #default>
            <div>
                <div v-if="showStartupMessage" id="startupMessage">
                    <div class="text-right">
                        <button @click="closeStartupMessage" class="close btn link">&times;</button>
                    </div>
                    <div class="container text-center" v-html="startupMessage"></div>
                </div>

                <div id="worksList" class="container">
                    <SearchBox @term-changed="updateFilter" />

                    <WorksList :search-filter="searchFilter" />
                </div>

                <div class="container text-center copyrights">
                    <p>
                        {{ $translate('copyrights.conception') }}: <a href="https://inetis.ch/" target="_blank">inetis</a><br />
                        &copy; {{ $translate('copyrights.photos') }}
                    </p>
                </div>
            </div>
        </template>

        <template #fallback>
            <div class="loading-page">
                <div class="spinner"></div>
            </div>
        </template>
    </Suspense>
</template>

<script>
import SearchBox from "@/components/SearchBox";
import WorksList from "@/components/WorksList";

export default {
    name: 'Works',
    components: {
        SearchBox,
        WorksList
    },

    data() {
        return {
            searchFilter: '',
            startupMessage: this.$translate('banner-message'),
            showStartupMessage: !this.$localStorage.get('hideStartupMessage', false),
        };
    },

    methods: {
        updateFilter(newValue) {
            this.searchFilter = newValue;
        },

        closeStartupMessage() {
            this.$localStorage.set('hideStartupMessage', true);
            this.showStartupMessage = false;
        }
    },
};
</script>

<style lang="scss" scoped>

#worksList {
    padding-top: 2.5rem;
}

#startupMessage {
    padding-bottom: 0.5rem;
    background-color: $blue-dark;
    color: $white;

    .btn.close {
        padding: 0 1rem;
        font-size: 2rem;
        color: $white;

        &:hover,
        &:active,
        &:focus {
            color: darken($white, 10%);
        }
    }
}

.copyrights {
    margin-top: 2rem;
    color: $text-muted-color;
}

</style>
