import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import StoragePlugin from 'vue-web-storage';
import AlertifyPlugin from '@/plugins/alertify';
import ApiPlugin from '@/plugins/api';
import TranslatePlugin from '@/plugins/translate';
import Default from "@/layouts/Default";
import Empty from "@/layouts/Empty";

let app = createApp(App);

app.use(router);
app.use(StoragePlugin);
app.use(ApiPlugin);
app.use(TranslatePlugin);
app.use(AlertifyPlugin);

app.component('default-layout', Default);
app.component('empty-layout', Empty);

app.config.globalProperties.$translateLoaded.then(() => {
    app.mount('#app');
});
