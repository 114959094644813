import axios from "axios";
import router from "@/router";

export default {
    install: (app) => {
        const authenticatedApi = function () {
            return axios.create({
                baseURL: 'https://artmotiers.ch/inetis/edition/api/',
                headers: {
                    'X-Auth-Token': app.config.globalProperties.$localStorage.get('authToken'),
                },
            });
        };

        const unauthenticatedApi = function () {
            return axios.create({
                baseURL: 'https://artmotiers.ch/inetis/edition/api/',
                headers: {},
            });
        };

        let dataCache = {
            works: null,
            artists: null,
        };

        const apiService = {
            login: async function() {
                return await unauthenticatedApi()
                    .post('login')
                    .then((response) => {
                        return response.data
                    });
            },

            logout: function() {
                app.config.globalProperties.$localStorage.remove('authToken');
            },

            getLangStrings: async function() {
                return await unauthenticatedApi()
                    .get('strings')
                    .then((response) => {
                        return response.data;
                    });
            },

            getWorks: async function() {
                dataCache.works = dataCache.works || await authenticatedApi()
                    .get('works')
                    .then((response) => {
                        return response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401 || err.response.status === 403) {
                            apiService.logout();
                            router.push('/');
                        }

                        throw err;
                    });

                return dataCache.works;
            },

            getWork: async function(workId) {
                let works = await apiService.getWorks() || [];

                for (let i = 0; i < works.length; i++) {
                    if (works[i].id === workId) {
                        return works[i];
                    }
                }

                throw new Error("work.not_found");
            },

            getWorksByArtist: async function(artistId) {
                return apiService.filterWorksByArtistSync(artistId, await apiService.getWorks());
            },

            filterWorksByArtistSync: function(artistId, works) {
                let artistWorks = [];

                for (let work of works) {
                    for (let workArtistId of work.artists) {
                        if (workArtistId === artistId) {
                            artistWorks.push(work);
                        }
                    }
                }

                return artistWorks;
            },

            getArtists: async function() {
                dataCache.artists = dataCache.artists || await authenticatedApi()
                    .get('artists')
                    .then((response) => {
                        return response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401 || err.response.status === 403) {
                            apiService.logout();
                            router.push('/');
                        }

                        throw err;
                    });

                return dataCache.artists;
            },

            getArtist: async function(artistId) {
                let artists = await apiService.getArtists() || [];

                for (let i = 0; i < artists.length; i++) {
                    if (artists[i].id === artistId) {
                        return artists[i];
                    }
                }

                throw new Error("work.not_found");
            },

            getArtistsForWork: async function(work) {
                return this.filterArtistsForWorkSync(await apiService.getArtists(), work);
            },

            filterArtistsForWorkSync: function(artists, work) {
                let workArtists = [];

                for (let artistId of work.artists) {
                    for (let artist of artists) {
                        if (artistId === artist.id) {
                            workArtists.push(artist);
                        }
                    }
                }

                return workArtists;
            }
        };

        app.config.globalProperties.$api = authenticatedApi;
        app.config.globalProperties.$unauthenticatedApi = unauthenticatedApi;
        app.config.globalProperties.$apiService = apiService;

        app.provide('api', authenticatedApi);
        app.provide('unauthenticatedApi', unauthenticatedApi);
        app.provide('apiService', apiService);
    }
};
