<template>
    <div class="container heading">
        <h1><i>{{ work.title }}</i></h1>

        <ul class="artists-list h1">
            <li v-for="artist in artists" :key="artist.id">
                <router-link :to="'/works/'+ work.id +'/artists/' + artist.id">
                    {{ artist.full_name }}
                </router-link>
            </li>
        </ul>

        <div class="work-id">{{ work.id }}</div>
    </div>


    <div class="images-container" v-if="work.images.length > 0">
        <images-carousel :images="work.images" :key="work.id" />
    </div>

    <div class="work-info container">
        <div v-if="work.technical.length > 0">
            <h2 class="h5">{{ $translate('work.info-title.technical') }} :</h2>
            <p>{{ work.technical }}</p>
        </div>

        <div v-if="work.dimensions.length > 0">
            <h2 class="h5">{{ $translate('work.info-title.dimensions') }} :</h2>
            <p>{{ work.dimensions }}</p>
        </div>

        <div v-if="work.description.length > 0">
            <h2 class="h5">{{ $translate('work.info-title.description') }} :</h2>
            <div class="richeditor-content" v-html="work.description"></div>
        </div>
    </div>

    <div class="works-nav-spacer"></div>
    <WorksNav :current-work-id="work.id" />
</template>

<script>
import {inject, ref} from "vue";
import {onBeforeRouteUpdate, useRoute} from "vue-router";
import ImagesCarousel from "@/components/ImagesCarousel";
import WorksNav from "./WorksNav";

export default {
    name : 'WorkDetail',

    components: {
        WorksNav,
        ImagesCarousel,
    },

    async setup() {
        const apiService = inject('apiService');
        const route = useRoute();

        const work = ref();
        const artists = ref();

        // Handle route change with Vue reusing the same component
        onBeforeRouteUpdate(async (to) => {
            work.value = await apiService.getWork(parseInt(to.params.id || ''));
            artists.value = await apiService.getArtistsForWork(work.value);
        });

        work.value = await apiService.getWork(parseInt(route.params.id || ''));
        artists.value = await apiService.getArtistsForWork(work.value);

        return {work, artists};
    },
}
</script>

<style lang="scss" scoped>

.heading {
    position: relative;
    padding-top: 0.375rem;
    padding-bottom: 0.825rem;

    & > h1 {
        margin-bottom: 0;
    }

    & > .work-id {
        position: absolute;
        z-index: 1000;
        right: 0;
        top: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        min-height: 60px;
        padding: 0.1em 0.25rem 0 0.25rem; // 0.1em padding at top to compensate for Gothic A1's broken line-height
        font-size: 1.25rem;
        line-height: 1;
        font-weight: bold;
        background-color: $blue-dark;
        color: $white;
    }
}

ul.artists-list {
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    text-overflow: clip;

    & > li {
        display: inline;

        &:not(:last-child)::after {
            content: "&";
            margin: 0 0.5rem;
            word-break: break-all;
        }

        &:last-child::after {
            content: "";
            margin-right: 60px;     // Prevent the text to pass behind the work-id
        }
    }
}

.work-info {

}

.works-nav-spacer {
    padding-bottom: 75px;
}

</style>
