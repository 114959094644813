import Alertify from "alertifyjs";

export default {
    install: (app) => {
        import('alertifyjs/build/css/alertify.css');

        Object.assign(Alertify.defaults, {
            notifier: {
                delay: 3,
                position: 'top-center',
            },
        });

        app.config.globalProperties.$alertify = Alertify;
        app.provide('alertify', Alertify);

        if (!Alertify.installPromptPopup) {
            Alertify.dialog('installPromptPopup', function() {
                return {
                    main: function(message) {
                        this.message = message;
                    },
                    setup: function() {
                        return {
                            buttons: [
                                {text: app.config.globalProperties.$translate('ios.install-close'), key: 27 /*ESC*/, className: 'btn link'},
                            ],
                            focus: {
                                element: 0,
                            },
                            options: {
                                title: app.config.globalProperties.$translate('ios.install-title'),
                                movable: false,
                                maximizable: false,
                                closableByDimmer: false,
                                closable: false,
                                resizable: false,
                            }
                        };
                    },
                    prepare: function() {
                        this.setContent(this.message);
                    }
                };
            });
        }
    },
};
