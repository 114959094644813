<template>
    <Suspense>
        <template #default>
            <WorkAuthorDetail />
        </template>

        <template #fallback>
            <div class="loading-page">
                <div class="spinner"></div>
            </div>
        </template>
    </Suspense>
</template>

<script>
import WorkAuthorDetail from "../components/WorkAuthorDetail";

export default {
    name: 'AuthorDetail',
    components: {
        WorkAuthorDetail,
    }
};
</script>
