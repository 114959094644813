<template>
    <div class="top-bar">
        <div class="section main">
            <h1 class="h3">{{ artist.full_name }}</h1>
        </div>

        <div class="section close">
            <router-link :to="'/works/' + work.id">
                <span class="v-align-middle">&times;</span>
            </router-link>
        </div>
    </div>

    <div class="image-full-container" v-if="artist.images.length > 0">
        <MultiImage :image="artist.images[0]" :height="200" />
    </div>

    <div class="artist-info container">
        <div v-if="artist.formatted_birth_date">
            <h2 class="h5">{{ $translate('artist.info-title.birth_date') }} :</h2>
            <p>{{ artist.formatted_birth_date }}</p>
        </div>

        <div v-if="artist.description.length > 0">
            <h2 class="h5">{{ $translate('artist.info-title.description') }} :</h2>
            <div class="richeditor-content" v-html="artist.description"></div>
        </div>

        <div v-if="artist.website.length > 0">
            <h2 class="h5">{{ $translate('artist.info-title.website') }} :</h2>
            <p>
                <a :href="artist.website" target="_blank">{{ artist.website }}</a>
            </p>
        </div>
    </div>

    <div class="artist-works container">
        <WorksList :artist-id="artist.id" search-filter="" />
    </div>

    <div class="works-nav-spacer"></div>
    <WorksNav :current-work-id="work.id" />
</template>

<script>
import {inject, ref} from "vue";
import {onBeforeRouteUpdate, useRoute} from "vue-router";
import WorksNav from "./WorksNav";
import WorksList from "./WorksList";
import MultiImage from "./MultiImage";

export default {
    name: 'WorkAuthorDetail',
    components: {MultiImage, WorksList, WorksNav},

    async setup() {
        const apiService = inject('apiService');
        const route = useRoute();

        let work = ref();
        let artist = ref();
        let artistWorks = ref();

        onBeforeRouteUpdate(async (to) => {
            work.value = await apiService.getWork(parseInt(to.params.id || ''));
            artist.value = await apiService.getArtistsForWork(work.value);
            artistWorks.value = await apiService.getWorksByArtist(artist.value.id);
        });

        work.value = await apiService.getWork(parseInt(route.params.workId || ''));
        artist.value = await apiService.getArtist(parseInt(route.params.authorId || ''));
        artistWorks.value = await apiService.getWorksByArtist(artist.value.id);

        return {work, artist};
    },
};
</script>

<style lang="scss" scoped>

.top-bar {
    display: flex;
    align-items: stretch;
    border-bottom: 2px solid $blue-light;

    & > .section:not(:first-child) {
        border-left: 2px solid $blue-light;
    }

    & > .section {
        display: flex;
        align-items: center;
        padding: 0.85rem 1rem;

        &.close {
            margin-left: auto;
            padding: 0;
            font-size: 2rem;
            line-height: 1;

            & > a {
                padding: 0.5rem 1.25rem
            }
        }
    }

    h1 {
        margin-bottom: 0;
    }
}

.artist-info {
    margin-top: 2rem;
}

.image-full-container {
    background-color: $gray-light;
    margin-bottom: 1.25rem;

    & > .image-container > img {
        display: block;
        width: 100%;
    }
}

.artist-works {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 2px solid $blue-light;
}

.works-nav-spacer {
    padding-bottom: 75px;
}

</style>
