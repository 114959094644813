export default {
    install: async (app) => {
        let strings = app.config.globalProperties.$localStorage.get('langStrings') || {};

        const translate = function(value) {
            return strings[value] || value;
        };

        app.config.globalProperties.$translate = translate;
        app.provide('translate', translate);

        let stringsLoading = new Promise((resolve, reject) => {
            let resolved = false;

            // Resolve the promise immediately if data could be fetched from cache
            if (Object.keys(strings).length > 0) {
                resolved = true;
                resolve();
            }

            app.config.globalProperties.$apiService
                .getLangStrings()
                .then((newStrings) => {
                    strings = newStrings;
                    app.config.globalProperties.$localStorage.set('langStrings', strings);

                    if (!resolved) {
                        resolve();
                    }
                })
                .catch((error) => {
                    if (!resolved) {
                        reject(error);
                    }
                });
        });

        app.config.globalProperties.$translateLoaded = stringsLoading;
        app.provide('translateLoaded', stringsLoading);
    }
};
