<template>
    <Suspense>
        <template #default>
            <WorkDetail />
        </template>

        <template #fallback>
            <div class="loading-page">
                <div class="spinner"></div>
            </div>
        </template>
    </Suspense>
</template>

<script>
import WorkDetail from "@/components/WorkDetail";

export default {
    name: 'Works',
    components: {
        WorkDetail
    }
};
</script>
