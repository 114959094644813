<template>
    <form @submit="sendForm">
        <div class="intro">
            <h2 class="h3">{{ $translate('login.title') }}</h2>
            <p>{{ $translate('login.label') }}</p>
        </div>

        <div class="form-group">
            <input
                type="text"
                v-model="code"
                class="form-control borderless"
                id="access_code"
                :placeholder="$translate('login.acess_code')"
            />
        </div>
        <div class="form-group">
            <input type="submit" class="btn primary xl full-width" :value="$translate('login.validate')" />
        </div>
    </form>
</template>

<script>
export default {
    name: 'LoginForm',
    data() {
        return {
            'code': '',
        };
    },
    mounted() {
        if (this.$localStorage.get('authToken')) {
            this.redirectToList();
        }

        if (this.shouldShowInstallPrompt) {
            this.showInstallPrompt();
        }
    },
    methods: {
        sendForm(e) {
            e.preventDefault();

            this.$unauthenticatedApi()
                .post('login', {code: this.code})
                .then((response) => {
                    this.$localStorage.set('authToken', response.data.token);
                    this.redirectToDownload();
                })
                .catch((error) => {
                    this.$alertify.notify(this.$translate(error.response.data.message), 'error');
                });
        },

        redirectToList() {
            this.$router.push('/works');
        },

        redirectToDownload() {
            this.$router.push('/download-content');
        },

        showInstallPrompt() {
            const iOsOptionsIcon = require('@/assets/images/icone_option_ios.svg');
            const iOsAddIcon = require('@/assets/images/icone_ajouter_ios.svg');
            const androidMenuIcon = require('@/assets/images/icone_android_menu.svg');
            const fireFoxInstallIcon = require('@/assets/images/icone_firefox_install.svg');
            const chromeInstallIcon = require('@/assets/images/icone_chrome_ajouter.svg');
            const samsungInstallIcon = require('@/assets/images/icone_samsung_install.svg');

            let message = '';

            if (this.isSafari) {
                message += `<p>${this.$translate('ios.install-intro')}</p>`;
                message += `<ol class="text-left install-steps">`;
                message += `<li>${this.$translate('ios.install-step.1')} <img src="${iOsOptionsIcon}"alt=""></li>`;
                message += `<li>${this.$translate('ios.install-step.2')} <img src="${iOsAddIcon}"alt=""></li>`;
                message += `<li>${this.$translate('ios.install-step.3')}</li>`;
                message += `</ol>`;
            }
            else if (this.isChromeAndroid) {
                message += `<p>${this.$translate('chrome.install-intro')}</p>`;
                message += `<ol class="text-left install-steps">`;
                message += `<li>${this.$translate('chrome.install-step.1')} <img src="${androidMenuIcon}"alt=""></li>`;
                message += `<li>${this.$translate('chrome.install-step.2')} <img src="${chromeInstallIcon}"alt=""></li>`;
                message += `</ol>`;
            }
            else if (this.isFirefoxAndroid) {
                message += `<p>${this.$translate('firefox.install-intro')}</p>`;
                message += `<ol class="text-left install-steps">`;
                message += `<li>${this.$translate('firefox.install-step.1')} <img src="${androidMenuIcon}"alt=""></li>`;
                message += `<li>${this.$translate('firefox.install-step.2')} <img src="${fireFoxInstallIcon}"alt=""></li>`;
                message += `</ol>`;
            }
            else if (this.isSamsungInternet) {
                message += `<p>${this.$translate('samsung.install-intro')}</p>`;
                message += `<ol class="text-left install-steps">`;
                message += `<li>${this.$translate('samsung.install-step.1')} <img src="${samsungInstallIcon}"alt=""></li>`;
                message += `</ol>`;
            }

            this.$alertify.installPromptPopup(message);
        },
    },

    computed: {
        shouldShowInstallPrompt() {
            const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);

            return (this.isSafari || this.isSamsungInternet || this.isChromeAndroid || this.isFirefoxAndroid) && !isInStandaloneMode;
        },

        isSafari() {
            return /Safari/i.test(window.navigator.userAgent) &&
                !(/Chrome/i.test(window.navigator.userAgent)); // Chrome wants to be Safari...
        },

        isSamsungInternet() {
            return /SamsungBrowser/i.test(window.navigator.userAgent);
        },

        isChromeAndroid() {
            return /Chrome/i.test(window.navigator.userAgent) &&
                !(/iphone|ipad|ipod/.test(window.navigator.userAgent));
        },

        isFirefoxAndroid() {
            return /Firefox/i.test(window.navigator.userAgent) &&
                !(/iphone|ipad|ipod/.test(window.navigator.userAgent));
        }
    }
}
</script>

<style lang="scss" scoped>

.h3 {
    margin-bottom: 0.5rem;
}

.intro {
    margin-bottom: 2rem;
}

.form-control {
    text-transform: lowercase;
}

</style>

<style lang="scss"> // Not scoped as it targets the content of a modal

.install-steps {
    & > li {
        margin-bottom: 0.2rem;

        img {
            margin-left: 0.25rem;
        }
    }
}

</style>
