<template>
    <form @submit="submitTerm" id="searchBox">
        <div class="form-group search-field">
            <div class="before-input">
                <img src="@/assets/images/search.svg" alt="" />
            </div>
            <input type="text" class="form-control text-center" v-model="searchTerm" :placeholder="$translate('search.placeholder')" />
            <div class="after-input">
                <button type="button" v-if="searchTerm !== ''" class="btn link" @click="clearSearch">
                    <img src="@/assets/images/close.svg" alt="" />
                </button>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    name: 'SearchBox',
    emits: ['termChanged'],

    data() {
        return {
            searchTerm: '',
        };
    },

    methods: {
        submitTerm(e) {
            e.preventDefault();

            this.$emit('termChanged', this.searchTerm);
        },

        clearSearch(e) {
            e.preventDefault();

            this.searchTerm = '';
            this.$emit('termChanged', this.searchTerm);
        }
    },

    watch: {
        searchTerm(value) {
            this.$emit('termChanged', value);
        }
    },
};
</script>

<style lang="scss" scoped>
    #searchBox {
        margin-bottom: 2rem;
    }

    .search-field {
        & > .before-input img,
        & > .after-input img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
</style>
