<template>
    <div class="home">
        <div class="container text-center">
            <h1 class="image-container">
                <img src="@/assets/images/home-logo.svg" alt="Môtiers 2021 Art en plein air" />
            </h1>

            <LoginForm />
        </div>
    </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
    name: 'Login',
    components: {
        LoginForm
    }
}
</script>

<style lang="scss" scoped>

.home {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $blue-dark;
    color: $white;

    & > .container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .image-container {
        margin-top: auto;
        margin-bottom: auto;
    }
}

</style>
