<template>
    <div id="workNav">
        <div class="container actions">
            <div class="action previous-work">
                <router-link :class="{disabled: (previousWork === null)}" class="action-link" :to="'/works/'+ (previousWorkId)">
                    <span>{{ previousWorkId }}</span>
                </router-link>
            </div>

            <div class="action back-works">
                <router-link class="action-link" :to="'/works'">
                    <img src="@/assets/images/home.svg" alt="Back" />
                </router-link>
            </div>

            <div class="action next-work">
                <router-link :class="{disabled: (nextWork === null)}" class="action-link" :to="'/works/'+ (nextWorkId)">
                    <span>{{ nextWorkId }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";

export default {
    name: 'WorksNav',

    async setup() {
        const apiService = inject('apiService');

        let allWorks = await apiService.getWorks();

        return {
            allWorks: allWorks,
        };
    },

    props: {
        currentWorkId: Number,
    },

    computed: {
        previousWork() {
            let previousWork = null;

            for (let i = 1; i < this.allWorks.length; i++) {
                if (this.allWorks[i].id === this.currentWorkId) {
                    previousWork = this.allWorks[i - 1];
                    break;
                }

            }

            return previousWork;
        },

        nextWork() {
            let nextWork = null;

            for (let i = 0; i < this.allWorks.length - 1; i++) {
                if (this.allWorks[i].id === this.currentWorkId) {
                    nextWork = this.allWorks[i + 1];
                    break;
                }
            }

            return nextWork;
        },

        previousWorkId() {
            let previousWork = this.previousWork;

            if (previousWork === null) {
                return this.currentWorkId;
            }

            return previousWork.id;
        },

        nextWorkId() {
            let nextWork = this.nextWork;

            if (nextWork === null) {
                return this.currentWorkId;
            }

            return nextWork.id;
        },
    },

    methods: {
    }
};
</script>

<style lang="scss" scoped>
    #workNav {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 75px;
        padding: 0.625rem 0;
        background-color: $blue-light;

        .actions {
            display: flex;
            justify-content: space-between;

            & > .action > .action-link {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
                font-weight: bold;
                line-height: 1;

                &.disabled {
                    visibility: hidden;
                }
            }

            & > .previous-work,
            & > .next-work {
                & > .action-link {
                    position: relative;
                    min-width: 30px;
                    min-height: 30px;
                    margin: 0 22px;
                    padding: 0.1em 2px 0 2px; // 0.1em padding at top to compensate for Gothic A1's broken line-height
                    border: 2px solid $white;
                }

                & > .action-link::before {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                & > span {

                }
            }

            & > .previous-work > .action-link::before {
                content: url("../assets/images/arrow-left.svg");
                right: 100%;
            }
            & > .next-work > .action-link::before {
                content: url("../assets/images/arrow-right.svg");
                left: 100%;
            }
        }
    }
</style>
