<template>
    <ul class="works-list">
        <li v-for="work in filteredWorks" :key="work.unique_id" class="work-item-container">
            <router-link class="work-item" :to="'/works/' + work.id">
                <div class="image-container">
                    <img :src="work.cover_image || defaultImage" alt="" />

                    <span class="work-id">{{ work.id }}</span>
                </div>

                <div class="info-container">
                    <div class="work-name">{{ work.title }}</div>
                    <ul class="authors-list">
                        <li v-for="artist in getWorkArtists(work)" :key="artist.id">
                            {{ artist.full_name }}
                        </li>
                    </ul>
                </div>
            </router-link>
        </li>
    </ul>

    <div class="no-results" v-if="filteredWorks.length === 0">
        <span class="h3">{{ $translate('search.no-results') }}</span>
    </div>
</template>

<script>
    import {inject} from "vue";

    export default {
        name : 'WorksList',

        props: {
            searchFilter: String,
            artistId: Number,
        },

        async setup() {
            const apiService = inject('apiService');

            return {
                works: await apiService.getWorks(),
                artists: await apiService.getArtists(),
                defaultImage: require('@/assets/images/default-list-item.png'),
            };
        },

        computed: {
            filteredWorks() {
                let filteredWorks = this.works;

                if (typeof this.artistId === "number" && this.artistId !== 0) {
                    filteredWorks = this.$apiService.filterWorksByArtistSync(this.artistId, filteredWorks);
                }

                if (typeof this.searchFilter !== 'string' || this.searchFilter === '') {
                    return filteredWorks;
                }

                let filterWords = this.searchFilter.split(' ');

                for (let word of filterWords) {
                    filteredWorks = filteredWorks.filter((work) => {
                        // Check if the work id matches
                        if (word === (work.id + '')) {
                            return true;
                        }

                        // Check if the work title matches
                        if (this.searchMatch(word, work.title)) {
                            return true;
                        }

                        // Check if one of the author name matches
                        for (let artist of this.$apiService.filterArtistsForWorkSync(this.artists, work)) {
                            if (this.searchMatch(word, artist.full_name)) {
                                return true;
                            }
                        }

                        return false;
                    });
                }

                return filteredWorks;
            },
        },

        methods: {
            searchMatch(term, into) {
                return this.normaliseSearchTerm(into).includes(this.normaliseSearchTerm(term))
            },

            normaliseSearchTerm(term) {
                term = term.toUpperCase();
                term = term.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                return term;
            },

            getWorkArtists(work) {
                return this.$apiService.filterArtistsForWorkSync(this.artists, work);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .works-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        & > .work-item-container > .work-item {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
            color: $body-color;

            & > .image-container {
                flex-shrink: 0;
                position: relative;
                margin-right: 1.6rem;
                width: 70px;

                & > img {
                    width: 100%;
                }
            }

            .work-id {
                position: absolute;
                top: 0;
                right: -15px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 30px;
                min-height: 30px;
                padding: 0.1em 2px 0 2px; // 0.1em padding at top to compensate for Gothic A1's broken line-height
                background-color: $blue-dark;
                color: $white;
                line-height: 1;
                font-weight: bold;
            }

            .info-container {
                margin-top: 0.25rem;

                & > *:not(:last-child) {
                    margin-bottom: 0.125rem;
                }

                & > .work-name {
                    line-height: 1.25;
                    font-style: italic;
                    color: $text-muted-color;
                }

                & > ul.authors-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    font-weight: bold;

                    & > li {
                        display: inline;
                    }
                    & > li:not(:last-child)::after {
                        content: "&";
                        margin: 0 0.5rem;
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .no-results {
        padding: 1.5rem 0;
        text-align: center;
    }
</style>
