import {createRouter, createWebHashHistory} from 'vue-router'
import Author from '../views/Author';
import Downloader from "../views/Downloader";
import Login from '../views/Login';
import Work from "../views/Work";
import Works from "../views/Works";

const routes = [
    {
        path : '/',
        name : 'Login',
        meta: { layout: "empty" },
        component: Login
    },
    {
        path: '/download-content',
        name: 'Content download',
        meta: { layout: "empty" },
        component: Downloader,
    },
    {
        path: '/works',
        name: 'Works',
        component: Works,
    },
    {
        path: '/works/:id',
        name: 'Work detail',
        component: Work,
    },
    {
        path: '/works/:workId/artists/:authorId',
        name: 'Author detail',
        component: Author,
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(() => {
    window.scrollTo(0, 0);
});

export default router;
